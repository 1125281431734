export const Work = [
    {
        id: 1,
        name: "Diario de viajes",
        description: "Aplicación web que te permite documentar y gestionar tus viajes de manera fácil y organizada.",
        tags: ["React", "Java"],

        demo: "https://www.pablomerinas.com/easytravels",
        github: "https://github.com/PabloMerinas/Proyecto-Final-Pablo-Merinas-Soto"
    },
    {
        id: 2,
        name: "Diario de viajes",
        description: "Aplicación web que te permite documentar y gestionar tus viajes de manera fácil y organizada.",
        tags: ["React", "Java"],

        demo: "https://www.pablomerinas.com/easytravels",
        github: "https://github.com/PabloMerinas/Proyecto-Final-Pablo-Merinas-Soto"
    },
    {
        id: 3,
        name: "Diario de viajes",
        description: "Aplicación web que te permite documentar y gestionar tus viajes de manera fácil y organizada.",
        tags: ["React", "Java"],

        demo: "https://www.pablomerinas.com/easytravels",
        github: "https://github.com/PabloMerinas/Proyecto-Final-Pablo-Merinas-Soto"
    }
]
